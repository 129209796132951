<template>
  <div class="p-fluid uk-width-1-1 uk-width-2-5@l">
    <div class="p-field">
      <label for="username">Логин</label>
      <InputText id="username" v-model="user.username" type="text"/>
    </div>
    <div class="p-field">
      <label for="password">Пароль</label>
      <InputText type="password" v-model="user.password" id="password"/>
    </div>
  </div>
  <Button @click="handleLogin">Войти</Button>
</template>

<script>
import UserCredentials from "@/services/auth/UserCredentials.js";
import InputText       from "primevue/inputtext/InputText";

export default {
  components: {
    InputText: InputText
  },
  name      : 'Login',
  data() {
    return {
      user   : new UserCredentials('', ''),
      loading: false,
      message: ''
    };
  },
  methods   : {
    handleLogin() {
      this.loading = true;

      if (this.user.username && this.user.password) {
        console.log('Login handle: ' + this.user.username + " : " + this.user.password)
        this.$auth.login({
          data: this.user
        })
      }
    }
  }
};
</script>